import { createTheme, responsiveFontSizes, adaptV4Theme } from "@mui/material";

// colors
const primary = "#0E5590"; /* BLEU AF */ /* NEW BLEU */ /*"#002157"; */
const primaryDarker = "#FAC8C5"; /*white*/
const primaryLighter = "#FEF4F3"; /*white*/

const secondary = "#F71D25"; /* ROUGE AF */
const secondaryDarker = "#9F8D7D"; /*brown/silk*/
const secondaryLighter = "#D5CDC6"; /*brown/silk*/

const tertiary = "#931116"; /*ROUGE FONCE AF*/
const tertiaryDarker = "#FED84A"; /*yellow/salomie*/
const tertiaryLighter = "#FFF3C9"; /*yellow/salomie*/

const quaternary = "#41AFE3"; /*BLEU CLAIR*/
const quaternaryDarker = "#FED84A"; /*yellow/salomie*/
const quaternaryLighter = "#FFF3C9"; /*yellow/salomie*/

const black = "#343a40";
const darkBlack = "rgb(36, 40, 44)";
const white = "#FBFBFB";
const lightWhite = "#FFFF";
const background = "#f5f5f5";
const darkBackground = "#141414f5";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 2;
const borderColor = "rgba(0, 0, 0, 0.13)";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

const mainFont = "Bahnschrift,PT Serif";

// spacing
const spacing = 8;

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: primary,
        darker: primaryDarker,
        lighter: primaryLighter,
      },
      secondary: {
        main: secondary,
        darker: secondaryDarker,
        lighter: secondaryLighter,
      },
      tertiary: {
        main: tertiary,
        darker: tertiaryDarker,
        lighter: tertiaryLighter,
      },
      quaternary: {
        main: quaternary,
        darker: quaternaryDarker,
        lighter: quaternaryLighter,
      },
      common: {
        black,
        darkBlack,
        white,
        lightWhite,
      },
      warning: {
        light: warningLight,
        main: warningMain,
        dark: warningDark,
      },
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      background: {
        default: background,
        dark: darkBackground,
      },
      spacing,
    },
    breakpoints: {
      // Define custom breakpoint values.
      // These will apply to Material-UI components that use responsive
      // breakpoints, such as `Grid` and `Hidden`. You can also use the
      // theme breakpoint functions `up`, `down`, and `between` to create
      // media queries for these breakpoints
      values: {
        xl,
        lg,
        md,
        sm,
        xs,
      },
    },
    border: {
      borderColor: borderColor,
      borderWidth: borderWidth,
    },
    overrides: {
      MuiExpansionPanel: {
        root: {
          position: "static",
        },
      },
      MuiTableCell: {
        root: {
          paddingLeft: spacing * 2,
          paddingRight: spacing * 2,
          borderBottom: `${borderWidth}px solid ${borderColor}`,
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: borderColor,
          height: borderWidth,
        },
      },
      MuiPrivateNotchedOutline: {
        root: {
          borderWidth: borderWidth,
        },
      },
      MuiListItem: {
        divider: {
          borderBottom: `${borderWidth}px solid ${borderColor}`,
        },
      },
      MuiDialog: {
        paper: {
          width: "100%",
          maxWidth: 430,
          marginLeft: spacing,
          marginRight: spacing,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: darkBlack,
        },
      },
      MuiExpansionPanelDetails: {
        root: {
          [`@media (max-width:  ${sm}px)`]: {
            paddingLeft: spacing,
            paddingRight: spacing,
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      allVariants: {
        fontFamily: mainFont,
        fontVariationSettings: '"wdth" 700',
        textTransform: "none",
        fontSize: "16px",
        fontWeight: " normal",
        letterSpacing: "0.05rem",
        color: black,
      },
      handWritingFont: {
        fontFamily: mainFont,
      },
      titleFont: {
        fontFamily: mainFont,
      },
      testText: {
        fontFamily: mainFont,
      },
      roundedFont: {
        fontFamily: mainFont,
      },
    },
    navBarHeight: "9rem",
    bannerTheme: {
      pageBanner: {
        overflow: "hidden",
        maxHeight: "40vh",
        height: "27rem",
        position: "relative",
      },
      pageBannerImgItem: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        opacity: "0.3",
      },
    },
    pageTitleTheme: {
      fontFamily: mainFont,
      width: "100%",
      textAlign: "center",
      fontSize: "2rem",
      fontWeight: "bold",
      padding: "2rem",
      color: primary,
      position: "relative",
      "&:before": {
        content: "''",
        position: "absolute",
        left: "50%",
        bottom: "1rem",
        width: "4rem",
        height: "0.1rem",
        background: secondary,
        transform: "translateX(calc( -1*4rem / 2))",
      },
    },
    pageContainerTheme: {
      width: "100%",
      textAlign: "left",
      fontSize: "1rem",
      color: black,
      margin: "auto",
      [`@media (min-width:  ${md}px)`]: {
        fontSize: "1rem",
      },
    },
    pageContentTheme: {
      color: black,
      margin: "auto",
      marginTop: "4rem",
      marginBottom: "4rem",
      width: "100%",
      maxWidth: "60rem",
      [`@media (max-width:  ${md}px)`]: {
        paddingRight: "2rem",
        paddingLeft: "2rem",
      },
    },
    doubleTitleTheme: {
      doubleTitleMain: {
        fontSize: "2rem",
        textWrap: "nowrap",
      },
      doubleTitleSub: {
        fontFamily: "Allura,Dancing Script",
        color: secondary,
        position: "absolute",
        fontSize: "1.5rem",
        zIndex: "-1",
        paddingLeft: "2rem",
        top: "-1rem",
        textWrap: "nowrap",
      },
    },
    buttonTheme: {
      padding: "1rem 2rem 1rem 2rem",
      background: secondary,
      width: "fit-content",
      margin: "auto",
      borderRadius: "0",
      color: white,
      fontSize: "1.2rem",
      "&:hover": {
        background: secondary + "99",
      },
    },
  })
);

export default responsiveFontSizes(theme);
