import React, { Fragment, Suspense, lazy } from "react";
import logo from "./logo.svg";
import "./App.css";

import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";

import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import theme from "./theme";

const MainComponent = lazy(() => import("./components/Main"));

function App() {
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={false} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<Fragment />}>
              <Switch>
                <Route>
                  <MainComponent />
                </Route>
              </Switch>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </CacheBuster>
  );
}

export default App;
